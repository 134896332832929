<template>
  <div class="message-container">
    <div class="inner">
      <el-breadcrumb class="breadcrumb" separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>消息中心</el-breadcrumb-item>
      </el-breadcrumb>
      <div class="wrap-row">
        <div class="tab-bar">
          <div class="t-tab">
            <div
              :class="['t-item', { 't-current': index === tabIndex }]"
              v-for="(item, index) in tabList"
              :key="index"
              @click="changeTab(index)"
            >
              {{ item }}
              <div class="t-line"></div>
            </div>
          </div>
        </div>
        <div class="t-btn" @click="readAll()">全部已读</div>
      </div>
      <div class="message-list" v-show="tabIndex == 1">
        <div v-if="!showNoData1">
          <div
            class="n-item"
            v-for="(item, index) in sysMsg"
            :key="item.id"
            @click="getSysDetail(item)"
          >
            <div class="n-top">
              <div class="m-dot" v-if="item.viewFlag == 'n' || item.viewFlag == null"></div>
              <span>系统消息</span>
              <font>{{ item.updateTime }}</font>
            </div>
            <p class="n-content" v-html="item.content"></p>
          </div>
        </div>
        <div v-else class="no-data">
          <div class="n-image"><span>暂无数据</span></div>
        </div>
      </div>
      <div class="message-list" v-show="tabIndex == 0">
        <div v-if="!showNoData2">
          <div
            class="n-item"
            v-for="(item, index) in cozMsg"
            :key="item.id"
            @click="getCozDetail(item)"
          >
            <div class="n-top">
              <div class="m-dot" v-if="item.viewFlag == 'n' || item.viewFlag == null"></div>
              <span>课程消息</span>
              <font>{{ item.updateTime }}</font>
            </div>
            <p class="n-content" v-html="item.content"></p>
          </div>
        </div>
        <div v-else class="no-data">
          <div class="n-image"><span>暂无数据</span></div>
        </div>
      </div>
      <el-pagination
        class="page mt50"
        prev-text="上一页"
        next-text="下一页"
        background
        layout="prev, pager, next"
        :total="totalPages"
        :page-size.sync="pageSize"
        @current-change="currentChange"
        :current-page.sync="pageIndex"
      ></el-pagination>
      <el-dialog title="消息详情" width="65%" :visible.sync="showMessage" class="dialog-container">
        <div class="container-wrap">
          <div class="n-item">
            <div class="n-top">
              <span v-if="msgDetail.msgType == 'sys'">系统消息</span>
              <span v-if="msgDetail.msgType == 'coz'">课程消息</span>
              <font>{{ msgDetail.updateTime }}</font>
            </div>
            <p class="n-content" v-html="msgDetail.content"></p>
          </div>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
export default {
  name: '',
  data() {
    return {
      messageNum: 0,
      showMessage: false,
      // 总条数
      totalPages: 0,
      // 每页条数
      pageSize: 10,
      // 当前页码
      pageIndex: 1,
      tabIndex: 0,
      tabList: ['课程消息', '系统消息'],
      // 系统消息列表
      sysMsg: [],
      // 课程消息列表
      cozMsg: [],
      // 是否有数据
      showNoData1: false,
      showNoData2: false,
      sysTotal: 0,
      cozTotal: 0,
      msgDetail: {},
      msgID: ''
    };
  },
  computed: {},
  activated() {
    this.getCozMesList();
    this.getSysMesList();
  },
  created() {
    this.getCozMesList();
    this.getSysMesList();
  },
  mounted() {},
  watch: {},
  methods: {
    // 更改 tab
    changeTab(index) {
      this.pageIndex = 1;
      this.tabIndex = index;
      if (this.tabIndex == 0) {
        this.totalPages = this.cozTotal;
        this.getCozMesListNormal();
      } else if (this.tabIndex == 1) {
        this.totalPages = this.sysTotal;
        this.getSysMesListNoral();
      }
    },
    // 获取课程消息列表
    getCozMesListNormal() {
      this.showNoData2 = false;
      this.get(
        '/biz/teacher/msg/cozMsg/list',
        { pageNum: this.pageIndex, pageSize: this.pageSize },
        e => {
          if (e.code == 200) {
            this.cozMsg = e.rows;
            this.cozTotal = e.total;
            if (e.total == 0) {
              this.showNoData2 = true;
            }
          } else {
            this.$.ui.toast(e.msg);
          }
        }
      );
    },
    // 获取系统消息列表
    getSysMesListNormal() {
      this.showNoData1 = false;
      this.get(
        '/biz/teacher/msg/sysMsg/list',
        { pageNum: this.pageIndex, pageSize: this.pageSize },
        e => {
          if (e.code == 200) {
            this.sysMsg = e.rows;
            this.sysTotal = e.total;
            if (e.total == 0) {
              this.showNoData1 = true;
            }
          } else {
            this.$.ui.toast(e.msg);
          }
        }
      );
    },
    // 获取课程消息列表
    getCozMesList() {
      this.showNoData2 = false;
      this.get(
        '/biz/teacher/msg/cozMsg/list',
        { pageNum: this.pageIndex, pageSize: this.pageSize },
        e => {
          if (e.code == 200) {
            this.cozMsg = e.rows;
            this.cozTotal = e.total;
            this.cozMsg.forEach(item => {
              if (item.viewFlag == 'n') {
                this.messageNum++;
              }
            });
            this.$store.state.messageNum = this.messageNum;
            if (e.total == 0) {
              this.showNoData2 = true;
            }
          } else {
            this.$.ui.toast(e.msg);
          }
        }
      );
    },
    // 获取系统消息列表
    getSysMesList() {
      this.showNoData1 = false;
      this.get(
        '/biz/teacher/msg/sysMsg/list',
        { pageNum: this.pageIndex, pageSize: this.pageSize },
        e => {
          if (e.code == 200) {
            this.sysMsg = e.rows;
            this.sysTotal = e.total;
            this.sysMsg.forEach(item => {
              if (item.viewFlag == 'n') {
                this.messageNum++;
              }
            });
            this.$store.state.messageNum = this.messageNum;
            if (e.total == 0) {
              this.showNoData1 = true;
            }
          } else {
            this.$.ui.toast(e.msg);
          }
        }
      );
    },
    readSysAll() {
      this.post('/biz/teacher/msg/sysMsg/viewAll', {}, e => {
        if (e.code == 200) {
          this.getSysMesList();
          this.$.ui.toast('系统消息全部已读');
        } else {
          this.$.ui.toast(e.msg);
        }
      });
    },
    readCozAll() {
      this.post('/biz/teacher/msg/cozMsg/viewAll', {}, e => {
        if (e.code == 200) {
          this.$.ui.toast('课程消息全部已读');
          this.getCozMesList();
        } else {
          this.$.ui.toast(e.msg);
        }
      });
    },
    readAll() {
      if (this.tabIndex == 1) {
        this.readSysAll();
      } else {
        this.readCozAll();
      }
      this.$router.go(0)
    },
    // 当前页变化时执行
    currentChange(e) {
      this.pageIndex = e;
      if (this.tabIndex == 0) {
        this.getCozMesListNormal();
      } else if (this.tabIndex == 1) {
        this.getSysMesListNormal();
      }
      document.body.scrollTop = 0
      // firefox
      document.documentElement.scrollTop = 0
      // safari
      window.pageYOffset = 0
    },
    // 获取课程消息详情
    getCozDetail(item) {
      this.msgID = item.id;
      item.viewFlag = 'y';
      this.get(`/biz/teacher/msg/cozMsg/detail?msgId=${this.msgID}`, {}, e => {
        if (e.code == 200) {
          this.msgDetail = e.data;
          this.showMessage = true;
          this.getCozMesList();
        } else {
          this.$.ui.toast(e.msg);
        }
      });
    },
    // 获取系统消息详情
    getSysDetail(item) {
      this.msgID = item.id;
      item.viewFlag = 'y';
      this.get(`/biz/teacher/msg/sysMsg/detail/${this.msgID}`, {}, e => {
        if (e.code == 200) {
          this.msgDetail = e.data;
          this.showMessage = true;
          this.getSysMesList();
        } else {
          this.$.ui.toast(e.msg);
        }
      });
    }
  },
  components: {}
};
</script>

<style lang="less">
.el-dialog {
  border-radius: 10px;
}
.dialog-container {
  /deep/ .el-dialog {
    border-radius: 10px !important;
  }
  .el-dialog__header {
    line-height: 30px;
    height: 30px;
    text-align: center;
    padding: 5px;
    background: #f5f5f7;
    border-radius: 10px 10px 0 0;
    .el-dialog__title {
      height: 20px;
      font-size: 14px;
      font-family: SF Pro Text;
      font-weight: 500;
      line-height: 20px;
      color: #45494d;
    }
  }
  .el-dialog__body {
    padding: unset;
    background: #ffffff;
    border-radius: 10px;
  }
  .el-dialog__body {
    border-radius: 10px;
  }
  .el-dialog__headerbtn {
    top: 15px;
  }
}
</style>
